import {formatCurrency} from '@brazilian-utils/brazilian-utils';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './boleto-details.module.scss';

function BoletoDetails({product, boleto}) {
  const {t} = useTranslation();

  return (
    <>
      {boleto && (
        <div className={`${styles.container_boleto_details} ${styles.margin} ${styles.align_center}`}>
          <strong>{t('BOLETO_LINK')}: <button onClick={() => window.open(boleto?.url, 'new')}>{boleto?.url}</button></strong>
        </div>
      )}
      <div className={styles.container_boleto_details}>
        <h3>{t('INFORMATION')} <span>{t('OF_BOLETO')}</span></h3>
        <hr />
        <div className={styles.line}>
          <p>{t('PRODUCT')}</p>
          <p>{product?.name}</p>
        </div>
        <hr />
        <div className={styles.line}>
          <p>{t('PURCHASE_DATE')}</p>
          <p>{moment().format('DD/MM/YYYY')}</p>
        </div>
        <hr />
        <div className={styles.line}>
          <p>{t('ORDER_NUMBER')}</p>
          <p>{product?.id.substring(0, 8)}</p>
        </div>
        <hr />
        <div className={styles.line}>
          <p>{t('TOTAL_COST')}</p>
          <p className={styles.price}>R$ {formatCurrency(product?.price / 100)}</p>
        </div>
      </div>
    </>
  );
}

export default BoletoDetails;
