import {formatCurrency} from '@brazilian-utils/brazilian-utils';
import Dialog from '@reach/dialog';
import Button from 'components/Button';
import useIsMounted from 'hooks/useIsMounted';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Lottie from 'react-lottie';
import {useDispatch, useSelector} from 'react-redux';

import IconModalClose from '../../assets/images/icon-modal-close.svg';
import PaymentFailed from '../../assets/lotties/payment-failed.json';
import PaymentSuccess from '../../assets/lotties/payment-success.json';
import styles from './ModalUpSell.module.scss';

const RESPONSIVE = {
  desktop: 1045,
  tablet: 768,
  phone: 425,
};

const getBanner = (width) => (
  width <= RESPONSIVE.phone
    ? 'phone'
    : ((width > RESPONSIVE.phone && width <= RESPONSIVE.tablet) ? 'tablet' : 'desktop')
);

const UpSellProduct = ({product, loading, onClick, translation: t}) => {
  return (
    <>
      <div className={styles.productBanner}>
        <img src={product?.banners[getBanner(window.innerWidth)]} alt='Banner' />
      </div>
      <div className={styles.content_modal}>
        <h3>{t('PRODUCT')} <span>{product?.name}</span></h3>
        <p>{product?.description}</p>
        <div className={styles.price}>
          <span>R$ {formatCurrency(product?.price / 100)}</span>
        </div>
        <div className={styles.alert_description}>
          {t('UPSELL_ALERT_DESCRIPTION')}
        </div>
        <Button loading={loading} onClick={onClick}>{product?.cta}</Button>
      </div>
    </>
  );
};

const UpSellResponse = ({status: statusRequest, loading, onClick, translation: t}) => {
  const [confirmationData, setConfirmationData] = useState({});

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: statusRequest === 200 ? PaymentSuccess : PaymentFailed,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    switch (true) {
    case statusRequest === 200:
      setConfirmationData({
        title: t('PAYMENT_SUCCESS'),
        description: t('PAYMENT_SUCCESS_DESCRIPTION'),
        classTitle: styles.success_title,
        showButton: false,
      });
      break;
    case statusRequest === 402:
      setConfirmationData({
        title: t('PAYMENT_FAILED_CARD'),
        description: t('PAYMENT_FAILED_CARD_DESCRIPTION'),
        classTitle: styles.failed_title,
        showButton: false,
      });
      break;
    case (statusRequest >= 500 && statusRequest <= 599):
      setConfirmationData({
        title: t('PAYMENT_FAILED'),
        description: t('PAYMENT_FAILED_500_DESCRIPTION'),
        classTitle: styles.failed_title,
        showButton: true,
      });
      break;
    default:
      setConfirmationData({
        title: t('PAYMENT_FAILED'),
        description: t('PAYMENT_FAILED_DESCRIPTION'),
        classTitle: styles.failed_title,
        showButton: false,
      });
    }
  }, [statusRequest, t]);

  return (
    <div className={styles.content_modal_response}>
      <h2>{t('CONFIRMATION')}</h2>
      <Lottie options={lottieOptions} width={215} height={180} style={{marginBottom: '25px'}} />
      <h3 className={confirmationData?.classTitle}>{confirmationData?.title}</h3>
      <p>{confirmationData?.description}</p>
      {confirmationData?.showButton && <Button loading={loading} onClick={onClick}>{t('TRY_AGAIN')}</Button>}
    </div>
  );
};

function ModalUpSell({onClose}) {
  const {t} = useTranslation();

  const {loading, submit} = useUpSellSubmission();

  const productUpSell = useSelector(ProductSelectors.upsell);
  const statusUpSell = useSelector(PaymentSelectors.statusUpSell);
  const upsellInstallments = useSelector(ProductSelectors.upsellInstallments);

  const session = useSelector(ProductSelectors.session);
  const customer = useSelector(CustomerSelectors.customer);
  const paymentInfo = useSelector(PaymentSelectors.payment);

  const handleSubmit = async () => {
    const payload = {
      ...session,
      customer,
      credit: {
        ...paymentInfo?.payment?.credit,
        installments: upsellInstallments[upsellInstallments.length - 1].installments,
        card: {
          ...paymentInfo?.payment?.credit?.card,
          amount: productUpSell?.price,
        },
      },
    };

    submit(payload);
  };

  useEffect(() => {
    document.body.classList.add('modal-open');
  }, []);

  return (
    <Dialog aria-label='upsell-modal'>
      <div className={styles.container_modal}>
        <section>
          <header>
            <h2>{t('EXCLUSIVE_OFFER')}</h2>
            <button onClick={onClose}>
              <img src={IconModalClose} alt='Close' />
            </button>
          </header>
          <div className={styles.body_modal}>
            {statusUpSell ? (
              <UpSellResponse status={statusUpSell} loading={loading} onClick={handleSubmit} translation={t} />
            ) : (
              <UpSellProduct product={productUpSell} loading={loading} onClick={handleSubmit} translation={t} />
            )}
          </div>
        </section>
      </div>
    </Dialog>
  );
}

export default ModalUpSell;

function useUpSellSubmission() {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  const submit = async (payload) => {
    try {
      setLoading(true);
      await dispatch(PaymentActions.submitUpSell(payload));
    } finally {
      isMounted && setLoading(false);
    }
  };

  return {loading, submit};
}
