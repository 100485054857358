const PaymentSelectors = {
  payment(state) {
    return state?.Payment;
  },

  statusUpSell(state) {
    return state?.Payment?.statusUpSell;
  },

  loading(state) {
    return state?.Payment.loading;
  },

  statusPix(state) {
    return state?.Payment?.statusPix;
  },

  getSeg(state) {
    return state?.Payment?.seg;
  },

  backForPaymentBoleto(state) {
    return state?.Payment?.backForPaymentBoleto;
  },

  pix(state) {
    return state?.Payment?.payment?.pix;
  },

  loadingPixQrCode(state) {
    return state?.Payment?.loadingPixQrCode;
  },

  pixExpired(state) {
    return state?.Payment?.pixExpired;
  },
};

export default PaymentSelectors;
