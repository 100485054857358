import {formatCurrency} from '@brazilian-utils/brazilian-utils';
import cardValidator from 'card-validator';

import config from './config';

export const formValidate = async (schema, subject) => {
  return schema.validate(subject, {abortEarly: false})
    .then((values) => ({isValid: true, values}))
    .catch((errors) => {
      return {
        isValid: false,
        errors: errors.inner.reduce((err, item) => {
          err[item.path] = item.message;
          return err;
        }, {}),
      };
    });
};

export const countdownTimer = counter => {
  if (counter > 0) {
    const hours = Math.floor(counter / 3600) > 9 ? Math.floor(counter / 3600) : `0${Math.floor(counter / 3600)}`;
    const minutes = Math.floor((counter % 3600) / 60) > 9 ? Math.floor((counter % 3600) / 60) : `0${Math.floor((counter % 3600) / 60)}`;
    const seconds = counter % 60 > 9 ? counter % 60 : `0${counter % 60}`;

    return `${hours}:${minutes}:${seconds}`;
  } else {
    return '00:00:00';
  }
};

export const formatDate = date => {
  if (!date) {
    return '';
  }
  return date.split('/').reverse().join('-');
};

export const handleValidationCard = value => {
  const numberValidation = cardValidator.number(value);
  return numberValidation.card;
};

export const converterCreditCardNames = (cardName) => {
  switch (cardName) {
  case 'visa':
    return 'Visa';
  case 'mastercard':
    return 'Master';
  case 'hipercard':
    return 'Hipercard';
  case 'amex':
    return 'Amex';
  case 'elo':
    return 'Elo';
  case 'dinersclub':
    return 'Diners';
  case 'discover':
    return 'Discover';
  default:
    return cardName;
  }
};

export const getCreditCardBrand = (brandName) => {
  switch (brandName) {
  case 'Master':
    return 'mastercard';
  case 'Diners':
    return 'dinersclub';
  default:
    return brandName.toLowerCase();
  }
};

export function formatCreditCard(input, formatter = [4, 4, 4, 4, 3], sep = ' ') {
  var output = '';
  var idx = 0;
  for (var i = 0; i < formatter.length && idx < input.length; i++) {
    output += input.substr(idx, formatter[i]);
    if (idx + formatter[i] < input.length) {
      output += sep;
    }
    idx += formatter[i];
  }
  output += input.substr(idx);
  return output;
}

export function convertToCurrency(value = 0) {
  return `R$ ${formatCurrency(value / 100)}`;
}

export function convertToCents(value = '0') {
  return Number(value.replace(/[R$.,\s]/g, ''));
}

export const currencyFormatter = value => {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format((value || 0) / 100);

  return `${amount}`;
};

export const isUniqueCard = (value, context) => {
  const cardOne = context?.from && context?.from[1]?.value?.firstCard;
  if (cardOne?.cardNumber === value) {
    return false;
  }
  return true;
};

export const obterTokenRecaptcha = async () => {
  return new Promise((resolve, reject) => {
    const grecaptcha = window.grecaptcha;
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(config.siteKeyRecaptcha, {action: 'PAYMENT'});
        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  });
};
