import SvgIcon from 'assets/SvgIcon';
import React from 'react';

import styles from './tabs.module.scss';

function Tabs({tabs = [], activedTab = 1, onActive, children}) {
  return (
    <div className={styles.container_tabs}>
      <ul>
        {tabs && tabs.map(({id, icon, title}) => (
          <li
            key={id}
            id={id}
            onClick={onActive}
            className={activedTab === id ? styles.active : ''}
          >
            {icon && <SvgIcon name={icon} />}
            {title}
          </li>
        ))}
      </ul>
      <section>
        {children}
      </section>
    </div>
  );
}

export default Tabs;
