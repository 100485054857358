import config from '../utils/config';

export const routes = {
  ROOT: '/',
  INITIAL: '/:code',
  PERSONAL_DATA: '/:code/personal-data',
  PAYMENT: '/:code/payment',
};

export const urls = {
  PRODUCT: `${config.apiBaseUrl}/v1/public/checkout/products/:code`,
  LEADS: `${config.apiBaseUrl}/v1/public/checkout/leads`,
  PAYMENT_CREDIT: `${config.apiBaseUrl}/v1/public/checkout/payments/credit`,
  PAYMENT_CREDITS: `${config.apiBaseUrl}/v1/public/checkout/payments/credits`,
  PAYMENT_BOLETO: `${config.apiBaseUrl}/v1/public/checkout/payments/boleto`,
  PAYMENT_PIX: `${config.apiBaseUrl}/v1/public/checkout/payments/pix`,
  STATES: `${config.countriesBaseUrl}/v1/countries/:country/states`,
  POSTAL_CODE: `${config.countriesBaseUrl}/v1/countries/:country/cep/verify`,
  STATUS_PIX: `${config.apiBaseUrl}/v1/public/checkout/payments/pix/status`,

  FILE_TERMS_OF_USE: `${config.cdnBaseUrl}/docs/termos_de_uso.txt`,
  FILE_PURCHASE_TERM: `${config.cdnBaseUrl}/docs/termos_de_compra.txt`,
  FILE_PRIVACY_POLICY: `${config.cdnBaseUrl}/docs/politica_de_privacidade.txt`,

  PING: `${config.apiBaseUrl}/v1/public/checkout/pings`,
};

export const masks = {
  cpfMask: [
    /[0-9]/, /\d/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '-',
    /\d/, /\d/,
  ],
  cnpjMask: [
    /[0-9]/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '.',
    /[0-9]/, /\d/, /\d/, '/',
    /[0-9]/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/,
  ],
  dateMask: [
    /[0-1]/, /[0-9]/, '/',
    /[1-9]/, /[0-9]/,
  ],
  cellPhoneMask: [
    '(', /[1-9]/, /\d/, ')',
    ' ', /\d/, /\d/, /\d/, /\d/,
    /\d/, '-', /\d/, /\d/, /\d/,
    /\d/, /\d/, /\d/, /\d/, /\d/,
  ],
  cardNumber: [
    /\d/, /\d/, /\d/, /\d/,
    ' ', /\d/, /\d/, /\d/,
    /\d/, ' ', /\d/, /\d/,
    /\d/, /\d/, ' ', /\d/,
    /\d/, /\d/, /\d/, ' ',
    /\d/, /\d/, /\d/, /\d/,
  ],
  postalCodeMask: [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
    /[0-9]/, '-', /[0-9]/, /[0-9]/,
    /[0-9]/,
  ],
  securityCodeMask: [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
  ],
  houseNumber: [
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
    /[A-Za-z0-9]/, /[A-Za-z0-9]/,
  ],
};

export const PriceFocusType = {
  Installment: 'Installment',
  Total: 'Total',
};
