import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import config from 'utils/config';

import {routes} from '../../utils/constants';
import browserHistory from '../../utils/history';
import {getProduct, ping} from './api';
import ProductSelectors from './selectors';

export const ProductActions = {
  getProduct: (code, params) => ({type: ActionTypes.ASYNC_PRODUCT, payload: {code, params}}),
  changeProduct: (product) => ({type: ActionTypes.CHANGE_PRODUCT, payload: product}),
  changeProductUpSell: (product) => ({type: ActionTypes.CHANGE_PRODUCT_UPSELL, payload: product}),
  changeStep: (step) => ({type: ActionTypes.CHANGE_STEP, payload: step}),
  changeSession: (payload) => ({type: ActionTypes.CHANGE_SESSION, payload}),
  updateSession: (payload) => ({type: ActionTypes.UPDATE_SESSION, payload}),
  cancelPing: () => ({type: ActionTypes.CANCEL_PING}),
};

const InitialState = {
  product: null,
  upsell: null,
  step: 1,
  cancelPing: false,
};

export const reducer = (state = InitialState, {type, payload}) => {
  switch (type) {
  case ActionTypes.CHANGE_PRODUCT:
    return {...state, product: payload};
  case ActionTypes.CHANGE_PRODUCT_UPSELL:
    return {...state, upsell: payload};
  case ActionTypes.CHANGE_STEP:
    return {...state, step: payload};
  case ActionTypes.CHANGE_SESSION:
    return {...state, product: {...state.product, ...payload}};
  case ActionTypes.UPDATE_SESSION:
    return state.product.session === payload.session && state.product.rev <= payload.rev
      ? {...state, product: {...state.product, ...payload}}
      : state;
  case ActionTypes.CANCEL_PING:
    return {...state, cancelPing: true};
  default:
    return state;
  }
};

const ProductSagas = {
  asyncGetProduct: function*({payload}) {
    try {
      const response = yield call(getProduct, payload);
      yield put(ProductActions.changeProduct(response));
      yield put({type: ActionTypes.ASYNC_PING});
    } catch (error) {
      const {status: statusCode} = error?.response;
      browserHistory.push(routes.ROOT, {statusCode});
    }
  },

  asyncPing: function*() {
    try {
      const session = yield select(ProductSelectors.session);
      const response = yield call(ping, session);
      yield put(ProductActions.updateSession(response));
    } finally {
      yield delay(config.pingPeriod * 1000);
      const cancelPing = yield select(ProductSelectors.cancelPing);

      if (!cancelPing) {
        yield put({type: ActionTypes.ASYNC_PING});
      }
    }
  },
};

export function* saga() {
  yield all([
    yield takeEvery(ActionTypes.ASYNC_PRODUCT, ProductSagas.asyncGetProduct),
    yield takeLatest(ActionTypes.ASYNC_PING, ProductSagas.asyncPing),
  ]);
}

const ActionTypes = {
  ASYNC_PRODUCT: '@product/ASYNC_PRODUCT',
  CHANGE_PRODUCT: '@product/CHANGE_PRODUCT',
  CHANGE_PRODUCT_UPSELL: '@product/CHANGE_PRODUCT_UPSELL',
  CHANGE_STEP: '@product/CHANGE_STEP',
  CHANGE_SESSION: '@product/CHANGE_SESSION',
  ASYNC_PING: '@product/ASYNC_PING',
  UPDATE_SESSION: '@product/UPDATE_SESSION',
  CANCEL_PING: '@product/CANCEL_PING',
};
