import {Get, Post} from '../../utils/apiBase';
import {urls} from '../../utils/constants';

export const postLeads = (payload) => {
  return Post(urls.LEADS, payload);
};

export const postPaymentCredit = (payload) => {
  return Post(urls.PAYMENT_CREDIT, payload);
};

export const getStates = ({country}) => {
  return Get(urls.STATES.replace(':country', country));
};

export const getPostalCode = ({country, postal_code: cep}) => {
  return Get(urls.POSTAL_CODE.replace(':country', country), {cep});
};

export const getModalTerms = (urlFile) => {
  return Get(urlFile);
};
