import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './Steps.module.scss';

function Steps({step = 0}) {
  const {t} = useTranslation();

  const stepsList = [
    {cod: '01', text: t('PERSONAL_DATA')},
    {cod: '02', text: t('PAYMENT')},
    {cod: '03', text: t('CONFIRMATION')},
  ];

  return (
    <div className={styles.container_steps}>
      {stepsList && stepsList.map((stepItem, index) => (
        <section className={styles.section_content} key={index} style={{flex: index === stepsList.length - 1 ? 1 : 2}}>
          <div style={{marginTop: `${stepItem.text === t('PERSONAL_DATA') && '14px'}`}} className={styles.step_item}>
            <div className={`${styles.number_wrapper} ${(step > index) ? styles.number_active : ''}`}>{stepItem.cod}</div>
            <div className={`${styles.text_wrapper} ${(step > index) ? styles.text_active : ''}`}>{stepItem.text}</div>
          </div>
          {index < stepsList.length - 1 && <div className={`${styles.divider} ${(step > index) ? styles.divider_active : ''}`} />}
        </section>
      ))}
    </div>
  );
}

export default Steps;
