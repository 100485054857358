import Tabs from 'components/Tabs';
import Boleto from 'modules/payment/components/Boleto';
import CreditCard from 'modules/payment/components/CreditCard';
import Pix from 'modules/payment/components/Pix';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import styles from './Payment.module.scss';

function Payment() {
  const {t} = useTranslation();
  const {product} = useSelector(ProductSelectors.product);
  const backForPaymentBoleto = useSelector(PaymentSelectors.backForPaymentBoleto);
  const [activedTab, setActivedTab] = useState(backForPaymentBoleto ? 2 : 1);

  const tabsWithBoleto = [
    {id: 1, icon: '', title: t('CREDIT_CARD')},
    {id: 2, icon: '', title: t('BOLETO')},
  ];

  const tabsWithPix = [
    {id: 1, icon: '', title: t('CREDIT_CARD')},
    {id: 3, icon: '', title: t('PIX')},
  ];

  const tabsAll = [
    {id: 1, icon: '', title: t('CREDIT_CARD')},
    {id: 2, icon: '', title: t('BOLETO')},
    {id: 3, icon: '', title: t('PIX')},
  ];

  return (
    <div className={styles.container_payment}>
      <h1>{t('PAYMENT')}</h1>
      {(product && (product?.boleto?.enabled && (!product?.boleto?.conditional || backForPaymentBoleto))) ? (
        <>
          <h2>{t('SELECT_PAYMENT_METHOD')}</h2>
          <Tabs tabs={product?.pix?.enabled ? tabsAll : tabsWithBoleto} activedTab={activedTab} onActive={e => setActivedTab(Number(e.target.id))}>
            {
              activedTab === 1 ? <CreditCard /> : activedTab === 2 ? <Boleto /> : <Pix />
            }
          </Tabs>
        </>
      ) : product?.pix?.enabled ?
        <>
          <h2>{t('SELECT_PAYMENT_METHOD')}</h2>
          <Tabs tabs={tabsWithPix} activedTab={activedTab} onActive={e => setActivedTab(Number(e.target.id))}>
            {
              activedTab === 1 ? <CreditCard /> : <Pix />
            }
          </Tabs>
        </> : <CreditCard />}
    </div>
  );
}

export default Payment;
