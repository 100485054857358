import ModalUpSell from 'components/ModalUpSell';
import BoletoDetails from 'modules/payment/components/Boleto/components/BoletoDetails';
import CardDetails from 'modules/payment/components/CreditCard/components/CardDetails';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Lottie from 'react-lottie';
import {useDispatch, useSelector} from 'react-redux';

import PaymentFailed from '../../assets/lotties/payment-failed.json';
import PaymentSuccessBoleto from '../../assets/lotties/payment-success-boleto.json';
import PaymentSuccess from '../../assets/lotties/payment-success.json';
import Button from '../../components/Button';
import {PaymentActions} from '../../modules/payment/redux';
import PaymentSelectors from '../../modules/payment/selectors';
import {ProductActions} from '../../modules/product/redux';
import styles from './PaymentConfirmation.module.scss';

function PaymentConfirmation() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [confirmationData, setConfirmationData] = useState({});
  const [hasUpSell, setHasUpSell] = useState(false);

  const productInfo = useSelector(ProductSelectors.product);
  const productUpSell = useSelector(ProductSelectors.upsell);
  const paymentInfo = useSelector(PaymentSelectors.payment);
  const boleto = paymentInfo?.payment?.boleto;
  const isConditional = productInfo?.product?.boleto?.conditional;

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: paymentInfo?.status === 200 ? (boleto ? PaymentSuccessBoleto : (paymentInfo?.payment?.pix ? PaymentSuccessBoleto : PaymentSuccess)) : PaymentFailed,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleCloseUpSell = () => {
    setHasUpSell(false);
    document.body.classList.remove('modal-open');
  };

  useEffect(() => {
    if (paymentInfo?.status) {
      switch (true) {
      case paymentInfo?.status === 200:
        productUpSell && setHasUpSell(true);
        setConfirmationData({
          title: boleto ? t('PAYMENT_SUCCESS_BOLETO') : t('PAYMENT_SUCCESS'),
          description: boleto ? '' : t('PAYMENT_SUCCESS_DESCRIPTION'),
          classTitle: styles.success_title,
          showButton: false,
        });
        break;
      case paymentInfo?.status === 402:
        setConfirmationData({
          title: boleto ? t('PAYMENT_FAILED') : t('PAYMENT_FAILED_CARD'),
          description: boleto ? t('PAYMENT_FAILED_BOLETO_DESCRIPTION') : t('PAYMENT_FAILED_CARD_DESCRIPTION'),
          classTitle: styles.failed_title,
          showButton: true,
          details: paymentInfo?.statusDetails,
        });
        break;
      case (paymentInfo?.status >= 500 && paymentInfo?.status <= 599):
        setConfirmationData({
          title: t('PAYMENT_FAILED'),
          description: boleto ? t('PAYMENT_FAILED_500_DESCRIPTION_BOLETO') : t('PAYMENT_FAILED_500_DESCRIPTION'),
          classTitle: styles.failed_title,
          showButton: true,
        });
        break;

      default:
        setConfirmationData({
          title: t('PAYMENT_FAILED'),
          description: t('PAYMENT_FAILED_DESCRIPTION'),
          classTitle: styles.failed_title,
          showButton: true,
        });
      }
    }
  }, [paymentInfo]) // eslint-disable-line

  const handleCheckoutBack = (withBoleto = false) => {
    if (withBoleto) {
      dispatch(PaymentActions.changeBackForPaymentBoleto(true));
    }
    dispatch(PaymentActions.changeBackForPayment(true));
    dispatch(PaymentActions.changeStatus(null));
    dispatch(ProductActions.changeStep(2));
  };

  return (
    <>
      <div className={styles.container_payment_confirmation}>
        <h2>{t('CONFIRMATION')}</h2>
        <Lottie options={lottieOptions} width={boleto ? 180 : 235} height={boleto ? 180 : 250} style={{marginBottom: '25px', marginTop: '25px'}} />
        <h3 className={confirmationData?.classTitle}>{confirmationData?.title}</h3>
        {boleto ? (
          <BoletoDetails product={productInfo?.product} boleto={boleto} />
        ) : (
          <>
            <p>{confirmationData?.description}</p>
            {confirmationData?.details && <CardDetails details={confirmationData.details} />}
            {confirmationData?.showButton && (
              <div className={styles.actions_container}>
                <Button onClick={() => handleCheckoutBack()}>{t(isConditional ? 'TRY_AGAIN_WITH_CC' : 'CHECKOUT_BACK')}</Button>
                {isConditional && <Button onClick={() => handleCheckoutBack(true)} outlined>{t('TRY_WITH_BOLETO')}</Button>}
              </div>
            )}
          </>
        )}
      </div>
      {hasUpSell && <ModalUpSell onClose={() => handleCloseUpSell()} />}
    </>
  );
}

export default PaymentConfirmation;
