import React from 'react';
import {useTranslation} from 'react-i18next';
import {convertToCurrency} from 'utils/functions';

import styles from './card-details.module.scss';

function CardDetails({details}) {
  const {t} = useTranslation();

  const cardError = details?.cards?.find(card => card.status === 'NOK');
  const cardIndex = details?.cards.indexOf(cardError);

  return (
    <div className={styles.container_card_details}>
      <h3>{t('INFORMATION')} <span>{t('CARD')} {cardIndex + 1}</span></h3>
      <hr />
      <div className={styles.line}>
        <p>{t('CARD_NUMBER')}</p>
        <p>{cardError?.number}</p>
      </div>
      <hr />
      <div className={styles.line}>
        <p>{t('PURCHASE_PRICE')}</p>
        <p>{convertToCurrency(cardError?.amount)} ({details?.installments}x {convertToCurrency(cardError?.amount / details?.installments)})</p>
      </div>
      <hr />
      <div className={styles.line}>
        <p>{t('CARD_STATUS')}</p>
        <p className={styles.error}>{t('UNPROCESSED_PURCHASE')}</p>
      </div>
    </div>
  );
}

export default CardDetails;
