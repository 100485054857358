import {Get, Post} from '../../utils/apiBase';
import {urls} from '../../utils/constants';

export const getProduct = ({code: productCode, params}) => {
  return Get(urls.PRODUCT.replace(':code', productCode), params);
};

export const ping = session => {
  return Post(urls.PING, session);
};
