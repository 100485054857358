import Dialog from '@reach/dialog';
import React from 'react';
import ContentLoader from 'react-content-loader';

import IconModalClose from '../../assets/images/icon-modal-close.svg';
import styles from './Modal.module.scss';

function Modal({title, children, onClose, justifyContent, marginT}) {
  return (
    <Dialog aria-label='modal'>
      <div className={styles.container_modal}>
        <section style={{justifyContent: `${justifyContent || 'center'}`, marginTop: `${marginT || ''}px`}}>
          <header>
            {title ? <span>{title}</span> : <LoadingHeader />}
            {onClose && <button onClick={onClose}>
              <img src={IconModalClose} alt='Close' />
            </button>}
          </header>
          <div className={styles.content_modal}>
            {children ? <pre>{children}</pre> : <LoadingBody />}
          </div>
        </section>
      </div>
    </Dialog>
  );
}

const LoadingHeader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      viewBox={'0 0 200 10'}
      backgroundColor='#e6e6e6'
      foregroundColor='#d8d8d8'
      {...props}
    >
      <rect x='60' y='0' rx='0' ry='0' width='80' height='10' />
    </ContentLoader>
  );
};

const LoadingBody = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      viewBox={'0 0 200 100'}
      backgroundColor='#e6e6e6'
      foregroundColor='#d8d8d8'
      {...props}
    >
      <rect x='0' y='0' rx='0' ry='0' width='200' height='5' />
      <rect x='0' y='10' rx='0' ry='0' width='200' height='5' />
      <rect x='0' y='20' rx='0' ry='0' width='200' height='5' />
    </ContentLoader>
  );
};

export default Modal;
