import {ProductActions} from '../product/redux';
import {postLeads} from './api';

export const CustomerActions = {
  submitCustomer: (payload) => {
    return async (dispatch) => {
      const response = await postLeads(payload);

      dispatch(CustomerActions.changeCustomer(payload.customer));
      dispatch(ProductActions.changeSession(response));
      dispatch(ProductActions.changeStep(2));
    };
  },
  changeCustomer: (customer) => ({type: ActionTypes.CHANGE_CUSTOMER, payload: customer}),
};

const InitialState = {
  customer: null,
};

export const reducer = (state = InitialState, {type, payload}) => {
  switch (type) {
  case ActionTypes.CHANGE_CUSTOMER:
    return {...state, customer: payload};
  default:
    return state;
  }
};

const ActionTypes = {
  CHANGE_CUSTOMER: '@customer/CHANGE_CUSTOMER',
};
