import {isValidCEP} from '@brazilian-utils/brazilian-utils';
import {PaymentActions} from 'modules/payment/redux';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import config from 'utils/config';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import useIsMounted from '../../hooks/useIsMounted';
import useWidth from '../../hooks/useWidth';
import {getModalTerms, getPostalCode, getStates} from '../../modules/customer/api';
import {CustomerActions} from '../../modules/customer/redux';
import ProductSelectors from '../../modules/product/selectors';
import {masks, urls} from '../../utils/constants';
import {formValidate} from '../../utils/functions';
import {personalDataSchema, personalDataSchemaCNPJ} from '../../utils/schemas';
import styles from './PersonalData.module.scss';

const RESPONSIVE_RESOLUTION = 720;
const ADDRESS_STREET_MAXLENGTH = 60;
const ADDRESS_COMPLEMENT_MAXLENGTH = 60;

export default function PersonalData() {
  const {t} = useTranslation();
  const width = useWidth();

  const [personalData, setPersonalData] = useState(Mapper.from);
  const [personalDataCNPJ, setPersonalDataCNPJ] = useState(MapperCNPJ.from);
  const [formValidation, setFormValidation] = useState({});
  const [states, setStates] = useState([]);
  const {loading, submit} = useCustomerSubmission();
  const [loadingPostalCode, setLoadingPostalCode] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [pfOrPj, setPfOrPj] = useState('cpf');
  const [userTimeout, setUserTimeout] = useState(null);

  const selectPfOrPj = useMemo(() => [
    {value: 'cpf', option: t('CPF')},
    {value: 'cnpj', option: t('CNPJ')},
  ], [t]);

  const session = useSelector(ProductSelectors.session);

  const handleValidateForm = async () => {
    const isFormValid = await formValidate(personalDataSchema, personalData);
    const isFormValidCNPJ = await formValidate(personalDataSchemaCNPJ, personalDataCNPJ);
    pfOrPj === 'cpf' ? setFormValidation(isFormValid) : setFormValidation(isFormValidCNPJ);

    return pfOrPj === 'cpf' ? isFormValid : isFormValidCNPJ;
  };

  const handleChange = e => {
    e.preventDefault();

    const {id, value} = e.target;

    setPersonalData({...personalData, [id]: value});
    setPersonalDataCNPJ({...personalDataCNPJ, [id]: value});

    if (value !== '') {
      setFormValidation({
        ...formValidation,
        errors: {
          ...formValidation.errors,
          [id]: undefined,
        },
      });
    }
  };

  const handleSubmitForm = async e => {
    e.preventDefault();

    const isFormValid = await handleValidateForm();

    if (isFormValid?.isValid) {

      const payload = {
        ...session,
        ...Mapper.to(personalData),
      };

      const payloadCNPJ = {
        ...session,
        ...MapperCNPJ.to(personalDataCNPJ),
      };

      await submit(pfOrPj === 'cpf' ? payload : payloadCNPJ);
    }
  };

  const handleOpenModal = async modal => {
    switch (modal) {
    case 'PURCHASE_TERMS':
      document.body.classList.add('modal-open');
      setIsOpenModal(true);
      setModalContent({
        title: t('PURCHASE_TERMS'),
        body: await getModalTerms(urls.FILE_PURCHASE_TERM),
      });
      break;

    case 'TERMS_OF_USE':
      document.body.classList.add('modal-open');
      setIsOpenModal(true);
      setModalContent({
        title: t('TERMS_OF_USE'),
        body: await getModalTerms(urls.FILE_TERMS_OF_USE),
      });
      break;

    case 'PRIVACY_POLICY':
      document.body.classList.add('modal-open');
      setIsOpenModal(true);
      setModalContent({
        title: t('PRIVACY_POLICY'),
        body: await getModalTerms(urls.FILE_PRIVACY_POLICY),
      });
      break;

    default:
      document.body.classList.remove('modal-open');
      setIsOpenModal(false);
      setModalContent({});
    }
  };

  const handleCloseModal = () => {
    document.body.classList.remove('modal-open');
    setIsOpenModal(false);
    setModalContent({});
  };

  const handlePostalCode = async (country, cep) => {
    try {
      setLoadingPostalCode(true);
      const response = await getPostalCode({country, postal_code: cep});

      setPersonalData({
        ...personalData,
        street: response.address,
        state: response.state,
        city: response.city,
        district: response.district,
      });

      setPersonalDataCNPJ({
        ...personalData,
        street: response.address,
        state: response.state,
        city: response.city,
        district: response.district,
      });

      const excludes = ['postalCode', 'street', 'state', 'city', 'district'];
      const auxErrors = {};
      Object.keys(formValidation.errors || []).map(key => {
        if (!excludes.includes(key)) {
          Object.assign(auxErrors, {[key]: formValidation.errors[key]});
        }
        return key;
      });

      setFormValidation({
        ...formValidation,
        errors: auxErrors,
      });
    } catch (error) {
      if (error.response.status === 404) {
        pfOrPj === 'cpf' ? (
          setPersonalData({
            ...personalData,
            street: '',
            state: '',
            city: '',
            district: '',
            cnpj: '',
            corporateName: '',
          })
        ) : (
          setPersonalDataCNPJ({
            ...personalData,
            street: '',
            state: '',
            city: '',
            district: '',
            cnpj: '',
            corporateName: '',
          })
        );
      }
    } finally {
      setLoadingPostalCode(false);
    }
  };

  const handleGetStates = async (country) => {
    try {
      const {states: statesList} = await getStates({country});
      const values = statesList.map(value => ({
        option: value.state,
        value: value.state,
      }));
      setStates(values);
    } catch (e) {
      // TODO: Notificar erro
    }
  };

  useEffect(() => {
    handleGetStates(personalData.country);
  }, [personalData.country]);

  useEffect(() => {
    const validPostalCode = isValidCEP(personalData.postalCode);
    if (validPostalCode && personalData.country) {
      const postalCode = String(personalData.postalCode).replace(/[-]+/g, '');
      clearTimeout(userTimeout);
      setUserTimeout(setTimeout(handlePostalCode, 1000, personalData.country, postalCode));
      // handlePostalCode(personalData.country, postalCode);
    }
  }, [personalData.postalCode]); // eslint-disable-line

  useEffect(() => {
    const validPostalCode = isValidCEP(personalDataCNPJ.postalCode);
    if (validPostalCode && personalDataCNPJ.country) {
      const postalCode = String(personalDataCNPJ.postalCode).replace(/[-]+/g, '');
      handlePostalCode(personalDataCNPJ.country, postalCode);
    }
  }, [personalDataCNPJ.postalCode]); // eslint-disable-line

  return (
    <div className={styles.container_personal_data}>
      <form onSubmit={handleSubmitForm}>
        <div className={styles.form_wrapper}>
          <h2>{t('DATA')} <span>{t('PERSONAL')}</span></h2>

          {config.featureFlagCNPJ && <Select
            id='select_personal'
            name='select_personal'
            title={t('SELECT_PERSONAL').toUpperCase()}
            placeholder={t('CPF')}
            data={selectPfOrPj}
            value={pfOrPj}
            onChange={e => setPfOrPj(e.target.value)}
          />}

          {pfOrPj === 'cpf' ? (<div className={styles.grid}>

            <Input
              id='firstName'
              name='personalData.firstName'
              title={t('FIRST_NAME').toUpperCase()}
              placeholder={t('FIRST_NAME')}
              value={personalData.firstName}
              error={pfOrPj === 'cpf' ? formValidation?.errors?.firstName : ''}
              onChange={handleChange}
            />
            <Input
              id='lastName'
              name='personalData.lastName'
              title={t('LAST_NAME').toUpperCase()}
              placeholder={t('LAST_NAME')}
              value={personalData.lastName}
              error={pfOrPj === 'cpf' ? formValidation?.errors?.lastName : ''}
              onChange={handleChange}
            />
          </div>) : <div className={styles.grid}>
            <Input
              id='corporateName'
              name='personalData.corporateName'
              title={t('SOCIAL_REASON').toUpperCase()}
              placeholder={t('SOCIAL_REASON')}
              value={personalData.corporateName}
              error={pfOrPj === 'cnpj' ? formValidation?.errors?.corporateName : ''}
              onChange={handleChange}
            />
          </div>}

          <div className={styles.grid}>
            {pfOrPj === 'cpf' ? <Input
              id='cpf'
              name='personalData.cpf'
              title={t('CPF').toUpperCase()}
              placeholder={t('CPF')}
              mask={masks.cpfMask}
              value={personalData.cpf}
              error={formValidation?.errors?.cpf}
              onChange={handleChange}
            /> : <Input
              id='cnpj'
              name='personalData.cnpj'
              title={t('CNPJ').toUpperCase()}
              placeholder={t('CNPJ')}
              mask={masks.cnpjMask}
              value={personalData.cnpj}
              error={formValidation?.errors?.cnpj}
              onChange={handleChange}
            />}
            <Input
              id='phone'
              name='personalData.phone'
              title={t('PHONE').toUpperCase()}
              placeholder={t('PHONE')}
              mask={masks.cellPhoneMask}
              value={personalData.phone}
              error={formValidation?.errors?.phone}
              onChange={handleChange}
            />
          </div>

          <div className={styles.grid}>
            <Input
              type='email'
              id='email'
              name='personalData.email'
              title={t('EMAIL').toUpperCase()}
              placeholder={t('EMAIL')}
              value={personalData.email}
              error={formValidation?.errors?.email}
              onChange={handleChange}
            />
            <Input
              type='email'
              id='confirmEmail'
              name='personalData.confirmEmail'
              title={t('EMAIL_CONFIRMATION').toUpperCase()}
              placeholder={t('EMAIL_CONFIRMATION')}
              value={personalData.confirmEmail}
              error={formValidation?.errors?.confirmEmail}
              onChange={handleChange}
              noPaste={true}
            />
          </div>

          <h2>{t('INFORMATION')} <span>{`${t('OF')} ${t('ADDRESS')}`}</span></h2>
          <div className={styles.grid}>
            <Input
              id='postalCode'
              name='personalData.postalCode'
              title={t('POSTAL_CODE').toUpperCase()}
              placeholder={t('POSTAL_CODE')}
              mask={masks.postalCodeMask}
              value={personalData.postalCode}
              error={formValidation?.errors?.postalCode}
              onChange={handleChange}
              inputWidth={width >= RESPONSIVE_RESOLUTION ? '30%' : '100%'}
            />
            <Input
              id='street'
              name='personalData.street'
              title={t('ADDRESS').toUpperCase()}
              placeholder={t('ADDRESS')}
              value={personalData.street}
              error={formValidation?.errors?.street}
              onChange={handleChange}
              disabled={loadingPostalCode}
              inputWidth={width >= RESPONSIVE_RESOLUTION ? '40%' : '100%'}
              maxLength={ADDRESS_STREET_MAXLENGTH}
            />
            <Input
              id='number'
              name='personalData.number'
              title={t('NUMBER').toUpperCase()}
              placeholder={t('NUMBER')}
              mask={masks.houseNumber}
              value={personalData.number}
              error={formValidation?.errors?.number}
              onChange={handleChange}
              disabled={loadingPostalCode}
              inputWidth={width >= RESPONSIVE_RESOLUTION ? '30%' : '100%'}
            />
          </div>

          <div className={styles.grid}>
            <Input
              id='complement'
              name='personalData.complement'
              title={t('COMPLEMENT').toUpperCase()}
              placeholder={t('COMPLEMENT')}
              value={personalData.complement}
              error={formValidation?.errors?.complement}
              onChange={handleChange}
              disabled={loadingPostalCode}
              optional={true}
              maxLength={ADDRESS_COMPLEMENT_MAXLENGTH}
            />
            <Input
              id='district'
              name='personalData.district'
              title={t('DISTRICT').toUpperCase()}
              placeholder={t('DISTRICT')}
              value={personalData.district}
              error={formValidation?.errors?.district}
              onChange={handleChange}
              disabled={loadingPostalCode}
            />
          </div>

          <div className={styles.grid}>
            <Select
              id='state'
              name='personalData.state'
              title={t('STATE').toUpperCase()}
              placeholder={t('STATE')}
              message='Selecione o seu estado'
              data={states}
              value={personalData.state}
              error={formValidation?.errors?.state}
              onChange={handleChange}
              disabled={loadingPostalCode}
            />
            <Input
              id='city'
              name='personalData.city'
              title={t('CITY').toUpperCase()}
              placeholder={t('CITY')}
              value={personalData.city}
              error={formValidation?.errors?.city}
              onChange={handleChange}
              disabled={loadingPostalCode}
            />
          </div>
        </div>

        <div className={styles.footer_wrapper}>
          <div className={styles.link_terms}>
            <p>
              {t('CHECK')} {t('THE_M')} <span onClick={() => handleOpenModal('PURCHASE_TERMS')}>{t('PURCHASE_TERMS')}</span>, <span onClick={() => handleOpenModal('TERMS_OF_USE')}>{t('TERMS_OF_USE')}</span> {t('AND')} {t('THE_F')} <span onClick={() => handleOpenModal('PRIVACY_POLICY')}>{t('PRIVACY_POLICY')}</span>
            </p>
          </div>
          <Button type='submit' loading={loading}>{t('NEXT_STEP')}</Button>
        </div>
      </form>

      {isOpenModal && (
        <Modal title={modalContent.title} onClose={() => handleCloseModal()}>
          {modalContent.body}
        </Modal>
      )}
    </div>
  );
}

function useCustomerSubmission() {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  const submit = async (payload) => {
    try {
      setLoading(true);
      dispatch(PaymentActions.changeLoading(true));
      await dispatch(CustomerActions.submitCustomer(payload));
      dispatch(PaymentActions.changeLoading(false));
    } finally {
      isMounted && setLoading(false);
      dispatch(PaymentActions.changeLoading(false));
    }
  };

  return {loading, submit};
}

const Mapper = {
  from: () => ({
    firstName: '',
    lastName: '',
    corporateName: '',
    email: '',
    confirmEmail: '',
    phone: '',
    cpf: '',
    cnpj: '',

    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',

    country: 'BRA',
  }),
  to: (personalData) => ({
    customer: {
      firstName: personalData.firstName,
      lastName: personalData.lastName,
      email: personalData.email.trim().toLowerCase(),
      phone: personalData.phone.replace(/[_().\s-]+/g, ''),
      document: {
        type: 'CPF',
        number: personalData.cpf.replace(/[.\s-]+/g, ''),
      },
      address: {
        postalCode: personalData.postalCode.replace(/[-]+/g, ''),
        street: personalData.street,
        number: personalData.number,
        complement: personalData.complement,
        district: personalData.district,
        city: personalData.city,
        state: personalData.state,
        country: 'Brazil',
      },
    },
  }),
};

const MapperCNPJ = {
  from: () => ({
    corporateName: '',
    email: '',
    confirmEmail: '',
    phone: '',
    cnpj: '',

    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',

    country: 'BRA',
  }),
  to: (personalDataCNPJ) => ({
    customer: {
      corporateName: personalDataCNPJ.corporateName,
      email: personalDataCNPJ.email.trim().toLowerCase(),
      phone: personalDataCNPJ.phone.replace(/[_().\s-]+/g, ''),
      document: {
        type: 'CNPJ',
        number: personalDataCNPJ.cnpj.replace(/[.\s-/]+/g, ''),
      },
      address: {
        postalCode: personalDataCNPJ.postalCode.replace(/[-]+/g, ''),
        street: personalDataCNPJ.street,
        number: personalDataCNPJ.number,
        complement: personalDataCNPJ.complement,
        district: personalDataCNPJ.district,
        city: personalDataCNPJ.city,
        state: personalDataCNPJ.state,
        country: 'Brazil',
      },
    },
  }),
};
