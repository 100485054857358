import React, {useRef, useState} from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';

import IconWarning from '../../assets/images/icon-warning.svg';
import {currencyFormatter} from '../../utils/functions';
import styles from './Input.module.scss';

const InputFormat = ({inputRef, mask, currency, onChange, onBlur, ...props}) => {
  if (currency) {
    return <NumberFormat {...props} type='tel' inputMode='numeric' onChange={onChange} onBlur={onBlur} format={currencyFormatter} ref={inputRef} />;
  }
  if (mask) {
    return <MaskedInput {...props} onChange={onChange} onBlur={onBlur} mask={mask} guide={false} ref={inputRef} />;
  }
  return <input {...props} onChange={onChange} onBlur={onBlur} ref={inputRef} />;
};

function Input({
  title,
  error,
  icon,
  mask,
  inputWidth = '100%',
  noPaste = false,
  currency = false,
  optional = false,
  onChange = () => {},
  onBlur = () => {},
  ...rest
}) {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const onPaste = e => {
    noPaste && e.preventDefault();
  };

  return (
    <div className={styles.container_input} style={{width: inputWidth}}>
      {title && (
        <div className={styles.title_input}>
          <p className={isFocused ? styles.isFocused : ''}>{title}</p>
          {optional && <small>(opcional)</small>}
        </div>
      )}
      <div
        className={styles.content_input}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {error && <img src={IconWarning} alt='Erro' />}
        <InputFormat
          inputRef={inputRef}
          currency={currency}
          mask={mask}
          onPaste={onPaste}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
      </div>
      {error && <p className={styles.error_caption}>{error}</p>}
    </div>
  );
}

export default Input;
