import {formatCPF} from '@brazilian-utils/brazilian-utils';
import i18next from 'i18next';

import {
  converterCreditCardNames, convertToCents,
  convertToCurrency,
  formatCreditCard,
  getCreditCardBrand,
} from '../../../../utils/functions';

export const CardMapper = {
  from: (card) => ({
    holderName: card?.holderName ?? '',
    cardNumber: card?.number ? formatCreditCard(card?.number) : '',
    dueDate: (card?.expirationMonth && card?.expirationYear) ? `${card?.expirationMonth}/${card?.expirationYear}` : '',
    securityCode: card?.securityCode ?? '',
    installment: '',
    cpf: card?.holderDocument?.number ? formatCPF(card?.holderDocument?.number, {pad: true}) : '',
    brand: card?.brand ? getCreditCardBrand(card?.brand) : '',
    value: convertToCurrency(card?.amount || 0),
  }),
  to: (card) => {
    const [expirationMonth, expirationYear] = card.dueDate.split('/');

    return {
      number: card.cardNumber.replace(/[_().\s-]+/g, ''),
      holderName: card.holderName,
      securityCode: card.securityCode,
      expirationMonth,
      expirationYear,
      brand: converterCreditCardNames(card.brand),
      holderDocument: {
        number: card.cpf.replace(/[.\s-]+/g, ''),
        type: 'CPF',
      },
      amount: convertToCents(card.value),
    };
  },
};

export const PaymentMapper = {
  from: (paymentInfo, productInfo) => {
    let creditCard = null;

    if (paymentInfo?.backForPayment) {
      const {withTwoCards, credit} = paymentInfo?.payment;
      creditCard = withTwoCards ? credit?.cards : [{...credit?.card, amount: productInfo?.product?.price}];
    }

    const installmentsMapper = productInfo?.product?.credit?.installments?.map(({installments, installmentAmount, price}) => ({
      value: installments,
      option: `${installments}x ${convertToCurrency((paymentInfo?.backForPayment && paymentInfo?.payment?.withTwoCards)
        ? (creditCard[0]?.amount / installments)
        : installmentAmount)} (${i18next.t('TOTAL')}: ${convertToCurrency(price)})`,
    }));

    return ({
      checkedTwoCards: !!paymentInfo?.payment?.withTwoCards,
      installments: installmentsMapper,
      firstCard: paymentInfo?.backForPayment
        ? CardMapper.from(creditCard[0])
        : CardMapper.from({amount: productInfo?.product?.price}),
      secondCard: (paymentInfo?.payment?.withTwoCards && paymentInfo?.backForPayment)
        ? CardMapper.from(creditCard[1])
        : null,
    });
  },
  to: (paymentData) => {
    return {
      installments: paymentData?.firstCard?.installment,
      card: paymentData?.checkedTwoCards ? undefined : CardMapper.to(paymentData.firstCard),
      cards: paymentData?.checkedTwoCards ? [CardMapper.to(paymentData.firstCard), CardMapper.to(paymentData.secondCard)] : undefined,
    };
  },
};
