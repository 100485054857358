import React, {useState, useEffect} from 'react';
import {usePaymentInputs} from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import styles from './InputCreditCard.module.scss';

function InputCreditCard({
  title,
  error,
  inputWidth = '100%',
  onChange = () => {},
  onBrand = () => {},
  ...rest
}) {
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
  } = usePaymentInputs();

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (meta && meta?.cardType?.type) {
      onBrand(meta?.cardType?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta?.cardType?.type]);

  return (
    <div className={styles.container_input_credit_card} style={{width: inputWidth}}>
      {title && (
        <div className={styles.title_input_credit_card}>
          <p className={isFocused ? styles.isFocused : ''}>{title}</p>
        </div>
      )}
      <div
        className={styles.content_input_credit_card}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <svg {...getCardImageProps({images})} />
        <input
          {...getCardNumberProps({onChange})}
          {...rest}
        />
      </div>
      {error && <p className={styles.error_caption}>{error}</p>}
    </div>
  );
}

export default InputCreditCard;
