import React from 'react';

import IconLoading from '../../assets/images/icon-loading.svg';
import styles from './Button.module.scss';

function Button({disabled = false, loading = false, outlined = false, children, ...rest}) {
  return (
    <button
      type='button'
      disabled={loading ? true : disabled}
      className={`${styles.btn} ${outlined ? styles.outlined : ''}`}
      {...rest}
    >
      {loading ? <img src={IconLoading} alt='Carregando' /> : children}
    </button>
  );
}

export default Button;
