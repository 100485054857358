import {ProductActions} from 'modules/product/redux';
import moment from 'moment';

import {postPaymentBoleto, postPaymentCredit, postPaymentCredits, postPaymentPix, postStatusPix} from './api';

export const PaymentActions = {
  changePayment: (payment) => ({type: ActionTypes.CHANGE_PAYMENT, payload: payment}),
  changeStatusPix: (payload) => ({type: ActionTypes.CHANGE_STATUS_PIX, payload: payload}),
  changeStatus: (statusRequest) => ({type: ActionTypes.CHANGE_STATUS, payload: statusRequest}),
  changeStatusDetails: (payload) => ({type: ActionTypes.CHANGE_STATUS_DETAILS, payload}),
  changeBackForPayment: (payload) => ({type: ActionTypes.CHANGE_BACK_FOR_PAYMENT, payload}),
  changeBackForPaymentBoleto: (payload) => ({type: ActionTypes.CHANGE_BACK_FOR_PAYMENT_BOLETO, payload}),
  changeStatusUpSell: (statusRequest) => ({type: ActionTypes.CHANGE_STATUS_UPSELL, payload: statusRequest}),
  submitBoleto: (payload) => {
    return async (dispatch) => {
      try {
        const response = await postPaymentBoleto(payload);
        const {session: newSession, rev, payment} = response;

        dispatch(PaymentActions.changeStatus(200));
        dispatch(ProductActions.changeSession({rev, session: newSession}));
        dispatch(PaymentActions.changePayment(payment));
        dispatch(ProductActions.cancelPing());

        if (payment?.boleto?.url) {
          window.open(payment?.boleto?.url, 'new');
        }
      } catch (err) {
        const {rev, session: newSession} = err?.response?.data;
        if (rev && newSession) {
          dispatch(ProductActions.changeSession({rev, session: newSession}));
        }
        dispatch(PaymentActions.changeStatus(err?.response?.data?.status || err?.response?.status));
      } finally {
        dispatch(ProductActions.changeStep(3));
      }
    };
  },
  submitCreditCard: (payload, checkedTwoCards) => {
    return async (dispatch) => {
      try {
        const response = await (checkedTwoCards ? postPaymentCredits(payload) : postPaymentCredit(payload));
        dispatch(PaymentActions.changeStatus(200));

        const {rev, session: newSession} = response;
        dispatch(ProductActions.changeSession({rev, session: newSession}));
        dispatch(ProductActions.cancelPing());

        if (response?.product) {
          dispatch(ProductActions.changeProductUpSell(response.product));
        }
      } catch (err) {
        const {rev, session: newSession} = err?.response?.data;
        if (rev && newSession) {
          dispatch(ProductActions.changeSession({rev, session: newSession}));
        }
        dispatch(PaymentActions.changeStatus(err?.response?.data?.status));
        dispatch(PaymentActions.changeStatusDetails(err?.response?.data?.credit));
      } finally {
        dispatch(PaymentActions.changeBackForPayment(false));
        dispatch(PaymentActions.changePayment({withTwoCards: checkedTwoCards, credit: payload.credit}));
        dispatch(ProductActions.changeStep(3));
      }
    };
  },
  submitUpSell: (payload) => {
    return async (dispatch) => {
      try {
        const response = await postPaymentCredit(payload);
        dispatch(PaymentActions.changeStatusUpSell(200));
        const {rev, session: newSession} = response;
        dispatch(ProductActions.changeSession({rev, session: newSession}));
      } catch (err) {
        if (!Array.from({length: 100}, (_, i) => i + 500).includes(err?.response?.data?.status)) {
          const {rev, session: newSession} = err?.response?.data;
          if (rev && newSession) {
            dispatch(ProductActions.changeSession({rev, session: newSession}));
          }
        }
        dispatch(PaymentActions.changeStatusUpSell(err?.response?.data?.status));
      }
    };
  },
  changeLoading: (payload) => ({type: ActionTypes.CHANGE_LOADING, payload}),
  submitStatusPix: (payload) => {
    return async (dispatch) => {
      try {
        const response = await postStatusPix(payload);
        dispatch(PaymentActions.changeStatusPix(response));
      } catch (err) {
        dispatch(PaymentActions.changeStatus(err?.response?.data?.status || err?.response?.status));
      }
    };
  },
  submitPix: (payload) => {
    return async (dispatch) => {
      try {
        dispatch(PaymentActions.changePixExpired(false));
        dispatch(PaymentActions.changeLoadingPixQrCode(true));
        const response = await postPaymentPix(payload);
        const {session: newSession, rev, payment} = response;

        // protegendo contra usuários que estão com a hora incorreta
        payment.pix.serverStartDate = payment.pix.startDate;
        payment.pix.serverExpirationDate = payment.pix.expirationDate;
        payment.pix.startDate = moment().toISOString(true);
        payment.pix.expirationDate = moment(payment.pix.startDate).add(payment.pix.expiration, 'seconds').toISOString(true);

        dispatch(ProductActions.changeSession({rev, session: newSession}));
        dispatch(PaymentActions.changePayment(payment));
        dispatch(PaymentActions.changeLoadingPixQrCode(false));

      } catch (err) {
        const {rev, session: newSession} = err?.response?.data;
        if (rev && newSession) {
          dispatch(ProductActions.changeSession({rev, session: newSession}));
        }
        dispatch(PaymentActions.changeStatus(err?.response?.data?.status || err?.response?.status));
      }
    };
  },
  pixApproved: () => {
    return async (dispatch) => {
      dispatch(ProductActions.cancelPing());
      dispatch(PaymentActions.changeStatus(200));
      dispatch(ProductActions.changeStep(3));
    };
  },
  changeLoadingPixQrCode: (payload) => {
    return {type: ActionTypes.CHANGE_LOADING_PIX_QRCODE, payload};
  },
  changePixExpired: (payload) => {
    return {type: ActionTypes.CHANGE_PIX_EXPIRED, payload};
  },
};

const InitialState = {
  payment: null,
  status: null,
  statusDetails: null,
  backForPayment: false,
  backForPaymentBoleto: false,
  statusUpSell: null,
  loading: false,
  statusPix: null,
  loadingPixQrCode: null,
  pixExpired: false,
};

export const reducer = (state = InitialState, {type, payload}) => {
  switch (type) {
  case ActionTypes.CHANGE_PAYMENT:
    return {...state, payment: payload};
  case ActionTypes.CHANGE_STATUS:
    return {...state, status: payload};
  case ActionTypes.CHANGE_STATUS_DETAILS:
    return {...state, statusDetails: payload};
  case ActionTypes.CHANGE_BACK_FOR_PAYMENT:
    return {...state, backForPayment: payload};
  case ActionTypes.CHANGE_BACK_FOR_PAYMENT_BOLETO:
    return {...state, backForPaymentBoleto: payload};
  case ActionTypes.CHANGE_STATUS_UPSELL:
    return {...state, statusUpSell: payload};
  case ActionTypes.CHANGE_LOADING:
    return {...state, loading: payload};
  case ActionTypes.CHANGE_STATUS_PIX:
    return {...state, statusPix: payload};
  case ActionTypes.CHANGE_LOADING_PIX_QRCODE:
    return {...state, loadingPixQrCode: payload};
  case ActionTypes.CHANGE_PIX_EXPIRED:
    return {...state, pixExpired: payload};
  default:
    return state;
  }
};

const ActionTypes = {
  CHANGE_PAYMENT: '@payment/CHANGE_PAYMENT',
  CHANGE_STATUS: '@payment/CHANGE_STATUS',
  CHANGE_STATUS_DETAILS: '@payment/CHANGE_STATUS_DETAILS',
  CHANGE_BACK_FOR_PAYMENT: '@payment/CHANGE_BACK_FOR_PAYMENT',
  CHANGE_BACK_FOR_PAYMENT_BOLETO: '@payment/CHANGE_BACK_FOR_PAYMENT_BOLETO',
  CHANGE_STATUS_UPSELL: '@payment/CHANGE_STATUS_UPSELL',
  CHANGE_LOADING: '@payment/CHANGE_LOADING',
  CHANGE_STATUS_PIX: '@payment/CHANGE_STATUS_PIX',
  CHANGE_LOADING_PIX_QRCODE: '@payment/CHANGE_LOADING_PIX_QRCODE',
  CHANGE_PIX_EXPIRED: '@payment/CHANGE_PIX_EXPIRED',
};
