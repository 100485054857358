import Button from 'components/Button';
import Input from 'components/Input';
import InputCreditCard from 'components/InputCreditCard';
import Select from 'components/Select';
import useIsMounted from 'hooks/useIsMounted';
import useWidth from 'hooks/useWidth';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Switch from 'react-switch';
import config from 'utils/config';
import {masks} from 'utils/constants';
import {formValidate, obterTokenRecaptcha} from 'utils/functions';
import {paymentSchema} from 'utils/schemas';

import styles from './credit-card.module.scss';
import usePaymentData from './hooks/usePaymentData';
import {PaymentMapper} from './mappers';

const RESPONSIVE_RESOLUTION = 720;

export default function CreditCard() {
  const {t} = useTranslation();

  const width = useWidth();

  const productInfo = useSelector(ProductSelectors.product);
  const session = useSelector(ProductSelectors.session);
  const customer = useSelector(CustomerSelectors.customer);
  const paymentInfo = useSelector(PaymentSelectors.payment);

  const [paymentData, setPaymentData] = usePaymentData(PaymentMapper.from(paymentInfo, productInfo));
  const {loading, submit, changeBackForPaymentBoleto} = useCreditCardSubmission();
  const [formValidation, setFormValidation] = useState({});

  const cardWithError = (paymentInfo?.backForPayment && paymentInfo?.payment?.withTwoCards)
    ? String(paymentInfo?.statusDetails?.cards?.findIndex(card => card.status === 'NOK') + 1)
    : undefined;

  const handleValidateForm = async () => {
    const isFormValid = await formValidate(paymentSchema, paymentData);
    setFormValidation(isFormValid);
    return isFormValid;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const {id, value} = e.target;

    setPaymentData(id, value);

    if (value !== '') {
      setFormValidation({
        ...formValidation,
        errors: {
          ...formValidation.errors,
          [id]: undefined,
        },
      });
    }
  };

  const handleSubmitForm = async e => {
    e.preventDefault();

    const isFormValid = await handleValidateForm();

    const token = await obterTokenRecaptcha();

    if (isFormValid?.isValid) {
      const payload = {
        ...session,
        customer,
        credit: PaymentMapper.to(paymentData),
        token,
      };

      await submit(payload, paymentData?.checkedTwoCards);
      changeBackForPaymentBoleto();
    }
  };

  return (
    <>
      {cardWithError && (
        <p className={styles.error_msg_two_cards}>{t('ERROR_MSG_TWO_CARDS')}</p>
      )}
      <form onSubmit={handleSubmitForm}>
        <div className={styles.form_wrapper}>
          <section className={(cardWithError && cardWithError === '1') ? styles.border_color_error : ''}>
            <div className={styles.grid}>
              <Input
                id='firstCard.holderName'
                name='paymentData.firstCard.holderName'
                title={t('HOLDER_NAME').toUpperCase()}
                placeholder={t('HOLDER_NAME')}
                value={paymentData.firstCard.holderName}
                error={formValidation?.errors && formValidation?.errors['firstCard.holderName']}
                onChange={handleChange}
              />
              <Input
                id='firstCard.cpf'
                name='paymentData.firstCard.cpf'
                title={t('CPF').toUpperCase()}
                placeholder={t('CPF')}
                mask={masks.cpfMask}
                value={paymentData.firstCard.cpf}
                error={formValidation?.errors && formValidation?.errors['firstCard.cpf']}
                onChange={handleChange}
              />
            </div>

            <div className={styles.grid}>
              <InputCreditCard
                id='firstCard.cardNumber'
                name='paymentData.firstCard.cardNumber'
                title={t('CARD_NUMBER').toUpperCase()}
                placeholder={t('CARD_NUMBER')}
                value={paymentData.firstCard.cardNumber}
                mask={masks.cardNumber}
                error={formValidation?.errors && formValidation?.errors['firstCard.cardNumber']}
                onChange={handleChange}
                onBrand={cardBrand => handleChange({preventDefault() {}, target: {id: 'firstCard.brand', value: cardBrand}})}
                inputWidth={width >= RESPONSIVE_RESOLUTION ? '50%' : '100%'}
              />
              <Input
                id='firstCard.dueDate'
                name='paymentData.firstCard.dueDate'
                title={t('DUE_DATE').toUpperCase()}
                placeholder={t('DUE_DATE')}
                mask={masks.dateMask}
                value={paymentData.firstCard.dueDate}
                error={formValidation?.errors && formValidation?.errors['firstCard.dueDate']}
                onChange={handleChange}
                inputWidth={width >= RESPONSIVE_RESOLUTION ? '26%' : '100%'}
              />
              <Input
                id='firstCard.securityCode'
                name='paymentData.firstCard.securityCode'
                title={t('CVV').toUpperCase()}
                placeholder={t('CVV')}
                mask={masks.securityCodeMask}
                value={paymentData.firstCard.securityCode}
                error={formValidation?.errors && formValidation?.errors['firstCard.securityCode']}
                onChange={handleChange}
                inputWidth={width >= RESPONSIVE_RESOLUTION ? '25%' : '100%'}
              />
            </div>

            <div className={styles.grid}>
              <Input
                id='firstCard.value'
                name='paymentData.firstCard.value'
                title={t('PURCHASE_PRICE').toUpperCase()}
                value={paymentData.firstCard.value}
                error={formValidation?.errors && formValidation?.errors['firstCard.value']}
                onChange={handleChange}
                className={paymentData?.checkedTwoCards ? styles.bold : styles.price}
                readOnly={!paymentData?.checkedTwoCards}
                currency
              />
              <Select
                id='firstCard.installment'
                name='paymentData.firstCard.installment'
                title={t('INSTALLMENT').toUpperCase()}
                placeholder={t('INSTALLMENT')}
                message='Parcela'
                data={paymentData.installments}
                value={paymentData.firstCard.installment}
                error={formValidation?.errors && formValidation?.errors['firstCard.installment']}
                onChange={handleChange}
              />
            </div>
          </section>

          {productInfo?.product?.credit?.allowTwoCards && (config.featureFlagTwoCards || !productInfo?.product?.producer?.franchise) && (
            <>
              <div className={styles.with_two_cards}>
                <p>{t('PAY_WITH_TWO_CARDS')}</p>
                <Switch
                  id='checkedTwoCards'
                  name='enabled'
                  className={styles.switch}
                  checked={paymentData?.checkedTwoCards}
                  onChange={value => handleChange({preventDefault() {}, target: {id: 'checkedTwoCards', value}})}
                  onColor='#c6e0a3'
                  onHandleColor='#88bd3f'
                  handleDiameter={22}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  height={15}
                  width={41}
                />
              </div>

              {paymentData?.secondCard && (config.featureFlagTwoCards || !productInfo?.product?.producer?.franchise) && (
                <section className={(cardWithError && cardWithError === '2') ? styles.border_color_error : ''}>
                  <div className={styles.second_card}>
                    <div className={styles.grid}>
                      <Input
                        id='secondCard.holderName'
                        name='paymentData.secondCard.holderName'
                        title={t('HOLDER_NAME').toUpperCase()}
                        placeholder={t('HOLDER_NAME')}
                        value={paymentData?.secondCard?.holderName}
                        error={formValidation?.errors && formValidation?.errors['secondCard.holderName']}
                        onChange={handleChange}
                      />
                      <Input
                        id='secondCard.cpf'
                        name='paymentData.secondCard.cpf'
                        title={t('CPF').toUpperCase()}
                        placeholder={t('CPF')}
                        mask={masks.cpfMask}
                        value={paymentData?.secondCard?.cpf}
                        error={formValidation?.errors && formValidation?.errors['secondCard.cpf']}
                        onChange={handleChange}
                      />
                    </div>

                    <div className={styles.grid}>
                      <InputCreditCard
                        id='secondCard.cardNumber'
                        name='paymentData.secondCard.cardNumber'
                        title={t('CARD_NUMBER').toUpperCase()}
                        placeholder={t('CARD_NUMBER')}
                        value={paymentData?.secondCard?.cardNumber}
                        mask={masks.cardNumber}
                        error={formValidation?.errors && formValidation?.errors['secondCard.cardNumber']}
                        onChange={handleChange}
                        onBrand={cardBrand => handleChange({preventDefault() {}, target: {id: 'secondCard.brand', value: cardBrand}})}
                        inputWidth={width >= RESPONSIVE_RESOLUTION ? '50%' : '100%'}
                      />
                      <Input
                        id='secondCard.dueDate'
                        name='paymentData.secondCard.dueDate'
                        title={t('DUE_DATE').toUpperCase()}
                        placeholder={t('DUE_DATE')}
                        mask={masks.dateMask}
                        value={paymentData?.secondCard?.dueDate}
                        error={formValidation?.errors && formValidation?.errors['secondCard.dueDate']}
                        onChange={handleChange}
                        inputWidth={width >= RESPONSIVE_RESOLUTION ? '26%' : '100%'}
                      />
                      <Input
                        id='secondCard.securityCode'
                        name='paymentData.secondCard.securityCode'
                        title={t('CVV').toUpperCase()}
                        placeholder={t('CVV')}
                        mask={masks.securityCodeMask}
                        value={paymentData?.secondCard?.securityCode}
                        error={formValidation?.errors && formValidation?.errors['secondCard.securityCode']}
                        onChange={handleChange}
                        inputWidth={width >= RESPONSIVE_RESOLUTION ? '25%' : '100%'}
                      />
                    </div>

                    <div className={styles.grid}>
                      <Input
                        id='secondCard.value'
                        name='paymentData.secondCard.value'
                        title={t('PURCHASE_PRICE').toUpperCase()}
                        value={paymentData?.secondCard?.value}
                        error={formValidation?.errors && formValidation?.errors['secondCard.value']}
                        className={styles.price}
                        readOnly={true}
                      />
                      <Input
                        id='secondCard.installment'
                        name='paymentData.secondCard.installment'
                        title={t('INSTALLMENT').toUpperCase()}
                        value={paymentData?.secondCard?.installment}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </section>
              )}
            </>
          )}
        </div>

        <div className={styles.footer_wrapper}>
          <Button type='submit' loading={loading}>{t('CHECKOUT')}</Button>
        </div>
      </form>
    </>
  );
}

function useCreditCardSubmission() {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  const submit = async (payload, checkedTwoCards) => {
    try {
      setLoading(true);
      await dispatch(PaymentActions.submitCreditCard(payload, checkedTwoCards));
    } finally {
      isMounted && setLoading(false);
    }
  };

  const changeBackForPaymentBoleto = () => {
    dispatch(PaymentActions.changeBackForPaymentBoleto(false));
  };

  return {loading, submit, changeBackForPaymentBoleto};
}
