import i18next from 'i18next';
import ProductSelectors from 'modules/product/selectors';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {convertToCents, convertToCurrency} from 'utils/functions';

import {CardMapper} from '../mappers';

function usePaymentData(initialValue) {
  const productInfo = useSelector(ProductSelectors.product);
  const [paymentData, changePaymentData] = useState(initialValue);

  function setPaymentData(fieldPath, value) {
    const newValue = setIn(paymentData, fieldPath, value);
    const selectedInstallment = productInfo?.product?.credit?.installments.filter(({installments}) => Number(installments) === Number(newValue.firstCard.installment));

    if (fieldPath === 'checkedTwoCards' && value === true) {
      newValue.secondCard = CardMapper.from();
    }
    if (fieldPath === 'checkedTwoCards' && value === false) {
      newValue.installments = productInfo?.product?.credit?.installments?.map(val => ({
        value: val.installments,
        option: `${val.installments}x ${convertToCurrency(val.installmentAmount)} (${i18next.t('TOTAL')}: ${convertToCurrency(val.price)})`,
      }));
      const firstCardValue = productInfo?.product?.credit?.installments?.find(({installments}) => Number(installments) === Number(newValue.firstCard.installment));
      newValue.firstCard.value = convertToCurrency(firstCardValue?.price || productInfo.product.price);
      newValue.secondCard = null;
    }
    if (fieldPath === 'firstCard.value' && paymentData?.checkedTwoCards) {
      const firstCardValue = convertToCents(newValue.firstCard.value);
      newValue.installments = productInfo?.product?.credit?.installments?.map(({installments}) => ({
        value: installments,
        option: `${installments}x ${convertToCurrency(firstCardValue / installments)}`,
      }));
    }
    if (fieldPath === 'firstCard.installment') {
      const {firstCard, secondCard} = newValue;
      const {installment} = firstCard;

      if (installment?.length && (convertToCents(secondCard?.value) === 0)) {
        if (selectedInstallment?.length) {
          newValue.firstCard.value = convertToCurrency(`${selectedInstallment[0]?.price}`);
        }
      }
    }
    if (newValue.secondCard) {
      const {price} = selectedInstallment[0] || productInfo?.product;
      const firstCardValue = convertToCents(newValue.firstCard.value);
      newValue.secondCard.value = convertToCurrency(firstCardValue >= price ? 0 : (price - firstCardValue));
      newValue.secondCard.installment = newValue.firstCard.installment !== ''
        ? `${newValue.firstCard.installment}x ${convertToCurrency(convertToCents(newValue.secondCard.value) / newValue.firstCard.installment)}`
        : '';
    }

    changePaymentData(newValue);
  }

  return [paymentData, setPaymentData];
}

export default usePaymentData;

function setIn(obj = {}, path = '', value) {
  if (!path) {
    return value;
  }

  const [field, ...rest] = path.split('.');

  return {
    ...obj,
    [field]: setIn(obj[field], rest.join('.'), value),
  };
}
