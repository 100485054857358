import React, {useState, useCallback} from 'react';

import IconArrowDown from '../../assets/images/icon-arrow-down.svg';
import styles from './Select.module.scss';

function Select({
  data,
  title,
  message,
  children,
  inputWidth = '100%',
  error,
  disabled = false,
  optional,
  defaultValue,
  onChange = () => { },
  ...rest
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  }, [isFocused]);

  return (
    <div className={styles.container_select} style={{width: inputWidth}}>
      {title && (
        <div className={styles.title_select}>
          <p>{title}</p>
          {optional && <small>(opcional)</small>}
        </div>
      )}
      <div className={`${styles.content_select} ${isFocused ? styles.isFocused : ''} ${disabled ? styles.isDisabled : ''}`}>
        <img src={IconArrowDown} alt='Arrow Down' />
        <select
          style={{color: (defaultValue === '' || defaultValue === undefined) ? 'var(--color-light-grey)' : 'var(--color-dark-grey)'}}
          value={defaultValue}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={handleInputFocus}
          disabled={disabled}
          {...rest}
        >
          {message && <option style={{color: 'var(--color-grey)'}} value=''>{message}</option>}
          {data && data.map(bank => (
            <option key={bank.value} value={bank.value}>{bank.option}</option>
          ))}
        </select>
        {children}
      </div>
      {error && <p className={styles.error_caption}>{error}</p>}
    </div>
  );
}

export default Select;
