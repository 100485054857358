import Modal from 'components/Modal';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './styles.module.scss';

export default function Countdown({expirationDate, onAccepted, onRejected, onTick, showModal}) {
  const {t} = useTranslation();
  const [elapsedTime, setElapsedTime] = useState({minutes: 0, seconds: 0});
  const {minutes, seconds} = elapsedTime;

  useEffect(() => {
    if (!showModal) {
      const intervalId = setInterval(() => {
        const currentTime = moment();
        const duration = moment.duration(expirationDate.clone().diff(currentTime.clone()));
        const min = duration.minutes() < 0 ? 0 : duration.minutes();
        const sec = duration.seconds() < 0 ? 0 : duration.seconds();
        setElapsedTime({minutes: min, seconds: sec});
        onTick(min, sec);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showModal, expirationDate, onTick]);

  return (
    <div className={styles.footer}>
      {t('QR_CODE_FOOTER1')}
      <span>{minutes > 9 ? minutes : `0${minutes}`}:{seconds > 9 ? seconds : `0${seconds}`} </span>
      {t('QR_CODE_FOOTER3')}
      {showModal === true && <Modal justifyContent={'flex-start'} marginT={80} title={t('QRCODE_EXPIRED')}>
        <div className={styles.container}>
          <h2>{t('NEW_QRCODE')}</h2>
          <div className={styles.buttons}>
            <button className={styles.button_accepted} onClick={() => onAccepted()}>{t('YES')}</button>
            <button className={styles.button_reject} onClick={() => onRejected()}>{t('NO')}</button>
          </div>
        </div>
      </Modal>}
    </div>
  );
}
