
import Button from 'components/Button';
import useIsMounted from 'hooks/useIsMounted';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import ProductSelectors from 'modules/product/selectors';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {obterTokenRecaptcha} from 'utils/functions';

import styles from './boleto.module.scss';
import BoletoDetails from './components/BoletoDetails';

function Boleto() {
  const {t} = useTranslation();

  const {loading, submit} = useBoletoSubmission();

  const productInfo = useSelector(ProductSelectors.product);
  const session = useSelector(ProductSelectors.session);
  const customer = useSelector(CustomerSelectors.customer);

  const handleGenerateBoleto = async () => {
    const token = await obterTokenRecaptcha();
    const payload = {...session, customer, token};
    submit(payload);
  };

  return (
    <div className={styles.container_boleto}>
      <BoletoDetails product={productInfo?.product} />

      <div className={styles.footer_wrapper}>
        <Button type='button' loading={loading} onClick={() => handleGenerateBoleto()}>{t('GENERATE_BOLETO')}</Button>
      </div>
    </div>
  );
}

export default Boleto;

function useBoletoSubmission() {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);

  const submit = async (payload) => {
    try {
      setLoading(true);
      await dispatch(PaymentActions.submitBoleto(payload));
    } finally {
      isMounted && setLoading(false);
    }
  };

  return {loading, submit};
}
