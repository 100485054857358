/* global process */
const config = {
  apiBaseUrl: process.env.REACT_APP_API_URL,
  cdnBaseUrl: process.env.REACT_APP_CDN_URL,
  countriesBaseUrl: process.env.REACT_APP_API_COUNTRIES,
  pingPeriod: process.env.REACT_APP_PING_PERIOD ? parseInt(process.env.REACT_APP_PING_PERIOD, 10) : 60,
  minProductPrice: process.env.REACT_APP_MIN_PRODUCT_PRICE ? parseInt(process.env.REACT_APP_MIN_PRODUCT_PRICE, 10) : 5000,
  maxProductPrice: 2147483647,
  featureFlagCNPJ: false,
  featureFlagTwoCards: false,
  siteKeyRecaptcha: process.env.REACT_APP_SITE_KEY_RECAPTCHA,
};

export default config;
