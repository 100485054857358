import SvgIcon from 'assets/SvgIcon';
import axios from 'axios';
import Button from 'components/Button';
import Countdown from 'components/Countdown';
import Tooltip from 'components/Tooltip';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
// import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {obterTokenRecaptcha} from 'utils/functions';
import notification from 'utils/notification';

import styles from './pix.module.scss';

const Pix = () => {
  const {t} = useTranslation();
  const [copied, setCopied] = useState(false);
  const statusPix = useSelector(PaymentSelectors.statusPix);
  const session = useSelector(ProductSelectors.session);
  const pix = useSelector(PaymentSelectors.pix);
  const loadingPixQrCode = useSelector(PaymentSelectors.loadingPixQrCode);
  const customer = useSelector(CustomerSelectors.customer);
  const pixExpired = useSelector(PaymentSelectors.pixExpired);
  const expirationDate = pix?.expirationDate;
  const urlMakePayment = pix?.dev?.url_make_payment;
  const dispatch = useDispatch();

  const onSubmitPix = useCallback(async () => {
    const token = await obterTokenRecaptcha();
    dispatch(PaymentActions.submitPix({session: session?.session, rev: session?.rev, customer, token}));
  }, [dispatch, session, customer]);

  useEffect(() => {
    if (loadingPixQrCode === null) {
      onSubmitPix();
    }
  }, [dispatch, loadingPixQrCode, session, customer, onSubmitPix]);

  useEffect(() => {
    if (loadingPixQrCode === false && pixExpired === false) {
      const checkPixStatus = setInterval(() => {
        dispatch(PaymentActions.submitStatusPix(session));
      }, 20000);

      return () => {
        clearInterval(checkPixStatus);
      };
    }
  }, [dispatch, loadingPixQrCode, pixExpired, session]);

  useEffect(() => {
    if (statusPix?.status === 'PAYMENT_APPROVED') {
      dispatch(PaymentActions.pixApproved());
    }
  }, [statusPix]); //eslint-disable-line

  const copyText = useCallback(async (text) => {
    if (!navigator.clipboard) {
      return;
    }

    if (!copied) {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      notification('success', 'código PIX copiado com sucesso!');
      setTimeout(() => setCopied(false), 2000);
    }

  }, [copied]);

  const handleConfirmationPayment = async () => {
    try {
      await axios.post(urlMakePayment);
    } catch (err) {
      console.log(err.response); //eslint-disable-line
    }
  };

  const onRejected = () => {
    document.location.reload(true);
  };

  const onTick = (minutes, seconds) => {
    if (minutes <= 0 && seconds <= 0) {
      dispatch(PaymentActions.changePixExpired(true));
    }
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>{t('QR_CODE_READER')}</span>

      <div className={styles.borderImg}>
        {loadingPixQrCode !== false ? <div className={styles.loading}></div> : <img className={styles.qrcode} alt='PIX QRCODE' src={pix?.qrcode} />}
      </div>

      <span className={styles.subtitle}>{t('QR_CODE_SUBTITLE')}</span>

      <div className={styles.link_qr_code}>
        <span>{pix?.emv ? pix?.emv : t('QR_CODE_LOADING_CODE')}</span>
        {loadingPixQrCode === false && <button
          type='button'
          onClick={() => copyText(pix?.emv)}
          className={styles.copy_button}
          data-tip data-for='copyTip'
        >
          <SvgIcon name='Copy' />
        </button>}
        {loadingPixQrCode === false && <Tooltip place='top' text={t('COPYPRODUCTLINK')} id='copyTip' />}
      </div>

      {loadingPixQrCode === false && expirationDate && <Countdown
        expirationDate={moment(expirationDate)}
        onAccepted={onSubmitPix}
        onRejected={onRejected}
        onTick={onTick}
        showModal={pixExpired}
      />}

      {loadingPixQrCode === false && urlMakePayment && <Button onClick={() =>
        handleConfirmationPayment()
      }>
        {t('CONFIRM_PAYMENT')}
      </Button>}
    </div>
  );
};

export default Pix;
