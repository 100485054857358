import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';

import {reducer as Customer} from '../../modules/customer/redux';
import {reducer as Payment} from '../../modules/payment/redux';
import {reducer as Product} from '../../modules/product/redux';

const RootReducer = (browserHistory) => combineReducers({
  router: connectRouter(browserHistory),
  Product,
  Customer,
  Payment,
});

export default RootReducer;
