import {isValidCPF, isValidCEP, isValidCNPJ} from '@brazilian-utils/brazilian-utils';
import i18next from 'i18next';
import moment from 'moment';
import * as yup from 'yup';

import {convertToCents, convertToCurrency, handleValidationCard, isUniqueCard} from '../utils/functions';
import config from './config';

export const personalDataSchema = yup.object({
  firstName: yup.string().max(40, i18next.t('LESS_THAN_40_CHAR')).required(i18next.t('REQUIRED_FIELD')),
  lastName: yup.string().max(80, i18next.t('LESS_THAN_80_CHAR')).required(i18next.t('REQUIRED_FIELD')),
  // SocialReason: yup.string().max(40, i18next.t('LESS_THAN_40_CHAR')).required(i18next.t('REQUIRED_FIELD')),
  email: yup.string()
    .trim()
    .lowercase()
    .email(i18next.t('INVALID_EMAIL'))
    .required(i18next.t('REQUIRED_FIELD')),
  confirmEmail: yup.string()
    .trim()
    .lowercase()
    .email(i18next.t('INVALID_EMAIL'))
    .test(
      'emailsEquals',
      i18next.t('EMAIL_NO_MATCH'),
      function (v) {
        const ref = yup.ref('email');
        return (v || '').toLowerCase() === (this.resolve(ref) || '').toLowerCase();
      },
    )
    .required(i18next.t('REQUIRED_FIELD')),
  cpf: yup.string()
    .test('cpf', i18next.t('INVALID_DOCUMENT'), async (value) => await isValidCPF(value))
    .required(i18next.t('REQUIRED_FIELD'))
    .min(11, i18next.t('CPF_AT_LEAST_11_CHAR')),
  phone: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .min(14, i18next.t('PHONE_AT_LEAST_10_CHAR'))
    .max(19, i18next.t('PHONE_MAX_15_CHAR')),
  postalCode: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .test('postalCode', i18next.t('INVALID_POSTALCODE'), async (value) => await isValidCEP(value))
    .matches(/(?!([089])\1{4})\d{5}/, i18next.t('INVALID_POSTALCODE')),
  street: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(60, i18next.t('MAX_CHAR', {val: '60'})),
  number: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(10, i18next.t('MAX_CHAR', {val: '10'})),
  complement: yup.string()
    .optional()
    .max(60, i18next.t('MAX_CHAR', {val: '60'})),
  district: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(255, i18next.t('MAX_CHAR', {val: '255'})),
  city: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(40, i18next.t('MAX_CHAR', {val: '40'})),
  state: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(20, i18next.t('MAX_CHAR', {val: '20'})),
  country: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(20, i18next.t('MAX_CHAR', {val: '20'})),
});

export const personalDataSchemaCNPJ = yup.object({
  corporateName: yup.string().max(40, i18next.t('LESS_THAN_40_CHAR')).required(i18next.t('REQUIRED_FIELD')),
  email: yup.string()
    .trim()
    .lowercase()
    .email(i18next.t('INVALID_EMAIL'))
    .required(i18next.t('REQUIRED_FIELD')),
  confirmEmail: yup.string()
    .trim()
    .lowercase()
    .email(i18next.t('INVALID_EMAIL'))
    .test(
      'emailsEquals',
      i18next.t('EMAIL_NO_MATCH'),
      function (v) {
        const ref = yup.ref('email');
        return (v || '').toLowerCase() === (this.resolve(ref) || '').toLowerCase();
      },
    )
    .required(i18next.t('REQUIRED_FIELD')),
  cnpj: yup.string()
    .test('cnpj', i18next.t('INVALID_DOCUMENT'), async (value) => await isValidCNPJ(value))
    .required(i18next.t('REQUIRED_FIELD'))
    .min(11, i18next.t('CNPJ_AT_LEAST_14_CHAR')),
  phone: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .min(14, i18next.t('PHONE_AT_LEAST_10_CHAR'))
    .max(19, i18next.t('PHONE_MAX_15_CHAR')),
  postalCode: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .test('postalCode', i18next.t('INVALID_POSTALCODE'), async (value) => await isValidCEP(value))
    .matches(/(?!([089])\1{4})\d{5}/, i18next.t('INVALID_POSTALCODE')),
  street: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(60, i18next.t('MAX_CHAR', {val: '60'})),
  number: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(10, i18next.t('MAX_CHAR', {val: '10'})),
  complement: yup.string()
    .optional()
    .max(60, i18next.t('MAX_CHAR', {val: '60'})),
  district: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(255, i18next.t('MAX_CHAR', {val: '255'})),
  city: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(40, i18next.t('MAX_CHAR', {val: '40'})),
  state: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(20, i18next.t('MAX_CHAR', {val: '20'})),
  country: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .max(20, i18next.t('MAX_CHAR', {val: '20'})),
});

const cardNumberSchema = yup.string()
  .test('cardNumber', i18next.t('INVALID_CARD'), value => handleValidationCard(value))
  .required(i18next.t('REQUIRED_FIELD'))
  .min(16, i18next.t('CARD_NUMBER_13_OR_MORE_CHAR'))
  .max(21, i18next.t('CARD_NUMBER_18_OR_LESS_CHAR'));

export const cardSchema = yup.object({
  holderName: yup.string()
    .max(25, i18next.t('NAME_MAX_25_CHAR'))
    .matches(/^[aA-zZ\s]+$/, i18next.t('NOT_NUMBERS_AND_SPECIAL_CHARS'))
    .required(i18next.t('REQUIRED_FIELD')),
  cpf: yup.string()
    .test('cpf', i18next.t('INVALID_DOCUMENT'), async (value) => await isValidCPF(value))
    .required(i18next.t('REQUIRED_FIELD'))
    .min(11, i18next.t('CPF_AT_LEAST_11_CHAR')),
  dueDate: yup.string()
    .test('due_date', i18next.t('INVALID_DATE'), async (value) => await moment(value, 'MM/YY', true).isValid())
    .required(i18next.t('REQUIRED_FIELD')),
  securityCode: yup.string()
    .required(i18next.t('REQUIRED_FIELD'))
    .min(3, i18next.t('CCV_GREATER_OR_EQUAL_3'))
    .max(4, i18next.t('CCV_LESS_OR_EQUAL_4')),
  cardNumber: cardNumberSchema,
  installment: yup.string()
    .required(i18next.t('REQUIRED_FIELD')),
});

const cardWithValueSchema = cardSchema.shape({
  value: yup.string()
    .test('value', i18next.t('VALUE_NOT_ALLOWED'), value => convertToCents(value) !== 0)
    .test('minValue', i18next.t('MIN_VALUE', {val: convertToCurrency(config.minProductPrice)}), value => convertToCents(value) >= config.minProductPrice)
    .required(i18next.t('REQUIRED_FIELD')),
});

export const paymentSchema = yup.object({
  checkedTwoCards: yup.boolean(),
  firstCard: yup.object().when('checkedTwoCards', {
    is: true,
    then: cardWithValueSchema,
    otherwise: cardSchema,
  }),
  secondCard: yup.object().when('checkedTwoCards', {
    is: true,
    then: cardWithValueSchema.shape({
      cardNumber: cardNumberSchema
        .test('cardUnique', i18next.t('EQUALS_CARDS'), function (value) {
          return isUniqueCard(value, this);
        }),
    }),
    otherwise: yup.object().nullable(),
  }),
});
