const ProductSelectors = {
  product(state) {
    return state?.Product?.product;
  },
  upsell(state) {
    return state?.Product?.upsell;
  },
  upsellInstallments(state) {
    return state?.Product.upsell?.credit?.installments;
  },
  session(state) {
    const {session, rev} = state?.Product?.product;
    return {session, rev};
  },
  step(state) {
    return state?.Product?.step;
  },
  cancelPing(state) {
    return state?.Product?.cancelPing;
  },

};

export default ProductSelectors;
