import {formatCurrency} from '@brazilian-utils/brazilian-utils';
import cuid from 'cuid';
import useUTMParameters from 'hooks/useUTMParameters';
import React, {useState, useEffect} from 'react';
import ContentLoader from 'react-content-loader';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {PriceFocusType} from 'utils/constants';

import IconArrowDown from '../../assets/images/icon-arrow-down.svg';
import Header from '../../components/Header';
import useWidth from '../../hooks/useWidth';
import {ProductActions} from '../../modules/product/redux';
import ProductSelectors from '../../modules/product/selectors';
import Payment from '../Payment';
import PaymentConfirmation from '../PaymentConfirmation';
import PersonalData from '../PersonalData';
import styles from './Initial.module.scss';

const RESPONSIVE = {
  desktop: 1045,
  tablet: 768,
  phone: 425,
};

const getBanner = (width) => (
  width <= RESPONSIVE.phone
    ? 'phone'
    : ((width > RESPONSIVE.phone && width <= RESPONSIVE.tablet) ? 'tablet' : 'desktop')
);

function ViewContainer({step}) {
  switch (step) {
  case 1: return <PersonalData />;
  case 2: return <Payment />;
  case 3: return <PaymentConfirmation />;
  default: return <div />;
  }
}

function Initial() {
  const {t} = useTranslation();
  const width = useWidth();
  const dispatch = useDispatch();
  const {params} = useRouteMatch();
  const UTMParameters = useUTMParameters();

  const [isExpanded, setIsExpanded] = useState(true);
  const [maxInstallments, setMaxInstallments] = useState({});

  const productInfo = useSelector(ProductSelectors.product);
  const step = useSelector(ProductSelectors.step);

  useEffect(() => {
    dispatch(ProductActions.getProduct(params?.code, {...UTMParameters, _: cuid()}));
  }, []); // eslint-disable-line

  const numberOfInstallments = productInfo?.product?.credit?.installments?.length;

  useEffect(() => {
    if (productInfo) {
      const {installments} = productInfo?.product?.credit;
      setMaxInstallments(installments[installments.length - 1]);
    }
  }, [productInfo]);

  useEffect(() => {
    if (step && step > 1) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [step]);

  return (
    <div className={styles.container_initial}>
      {!productInfo?.product ? <Loading /> : (
        <>
          <Header />
          <div className={styles.content_initial}>
            <aside>
              <div className={styles.content_initial_header}>
                <div className={styles.content_initial_header_title}>
                  <h2>{t('PRODUCT')} <span>{productInfo?.product?.name}</span></h2>
                  <p>{t('DELIVERY_BY')}: <strong>{productInfo?.product?.producer?.name}</strong></p>
                </div>
                {width < RESPONSIVE.desktop && (
                  <img
                    src={IconArrowDown}
                    alt='Arrow'
                    onClick={() => setIsExpanded(!isExpanded)}
                    className={`${styles.img_arrow_down} ${isExpanded ? styles.rotate_180 : ''}`}
                  />
                )}
              </div>

              <div className={`${styles.content_initial_body} ${(!isExpanded && width < RESPONSIVE.desktop) ? styles.hidden : ''}`}>
                <div>
                  <img src={productInfo?.product?.banners[getBanner(width)]} alt='Produto' />
                </div>

                {productInfo?.product?.description && (
                  <>
                    <h3>{t('PRODUCT_DESCRIPTION')}</h3>
                    <article>
                      {productInfo?.product?.description}
                    </article>
                  </>
                )}

                <section>
                  {productInfo?.product?.credit?.priceFocus === PriceFocusType.Total
                    ? (
                      <>
                        <span>R$ {formatCurrency(productInfo?.product?.price / 100)} {t('IN_CASH')}</span>
                        <small><span className={`${styles.avista}`}>{t('OR')} R$ {maxInstallments?.installments}x {t('OF')} R$ {formatCurrency(maxInstallments?.installmentAmount / 100)}<span className={`${styles.asterisc}`}>*</span></span></small>
                        <small><span className={`${styles.credit}`}> {t('IN_CREDIT_CARD')}</span></small>
                      </>
                    )
                    : (
                      <>
                        <span>
                          {numberOfInstallments !== 1 ? `${maxInstallments?.installments}x  ${t('OF')} ` : null}
                          R$ {formatCurrency(maxInstallments?.installmentAmount / 100)}<span className={`${styles.asterisc}`}>*</span></span>
                        {
                          numberOfInstallments === 1 ?
                            null
                            : <small>{t('OR')} R$ {formatCurrency(productInfo?.product?.price / 100)} <span className={`${styles.avista}`}> {t('IN_CASH')} </span></small>}
                        <small><span className={`${styles.credit}`}> {t('IN_CREDIT_CARD')}</span></small>
                      </>
                    )
                  }
                </section>
              </div>
            </aside>
            <main>
              <ViewContainer step={step} />
            </main>
          </div>
        </>
      )}
    </div>
  );
}

const Loading = (props) => {
  const width = useWidth();

  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      viewBox={`0 0 ${width >= RESPONSIVE.desktop ? 400 : 200} ${width >= RESPONSIVE.desktop ? 200 : 400}`}
      backgroundColor='#e6e6e6'
      foregroundColor='#d8d8d8'
      {...props}
    >
      {width >= RESPONSIVE.desktop ? (
        <>
          <rect x='182' y='0' rx='2' ry='2' width='35' height='15' />
          <rect x='101' y='22' rx='2' ry='2' width='198' height='15' />

          <rect x='0' y='50' rx='2' ry='2' width='135' height='170' />
          <rect x='142' y='50' rx='2' ry='2' width='268' height='170' />
        </>
      ) : (
        <>
          <rect x='82' y='0' rx='2' ry='2' width='35' height='15' />
          <rect x='0' y='22' rx='2' ry='2' width='200' height='20' />

          <rect x='0' y='50' rx='2' ry='2' width='200' height='170' />
          <rect x='0' y='230' rx='2' ry='2' width='200' height='170' />
        </>
      )}
    </ContentLoader>
  );
};

export default Initial;
